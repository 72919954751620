<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column mt-5">
        <span class="card-label font-weight-bolder text-dark">
          {{ $t("GENERAL.GIFTS_SENT") }}
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm" v-if="false"> More than 400+ new members </span>
      </h3>
    </div>
    <hr class="mb-10" />
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0 shadow-xs">
      <!--begin::Table-->
      <div class="blockquote" v-if="dataToShow.length === 0 && status !== 'loading'">
        {{ $t("GENERAL.NO_RECORDS_FOUND") }}
      </div>
      <div class="blockquote justify-content-center" v-if="status === 'loading'">
        <b-row class="justify-content-md-center">
          <b-col cols="12" md="auto"><b-spinner variant="success"></b-spinner></b-col>
        </b-row>
      </div>

      <div class="table-responsive h-100 p-2" v-if="dataToShow.length > 0 && status !== 'loading'">
        <table class="table table-borderless table-vertical-center rounded striped">
          <thead>
            <tr>
              <th class="p-0" style="width: 50px"></th>

              <th class="p-0" style="min-width: 200px">{{ $tc("GENERAL.PRODUCT", 1) }}</th>
              <th class="p-0" style="min-width: 40px">{{ $tc("GENERAL.GIFT_SENT", 1) }}</th>

              <th class="p-0" style="min-width: 100px">{{ $tc("GENERAL.TOKEN", 1) }}</th>
              <th class="p-0" style="min-width: 150px">{{ $tc("GENERAL.STATUS", 1) }}</th>
              <th class="p-0" style="min-width: 90px">...</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in dataToShow">
              <tr v-bind:key="i" class="">
                <td class="pl-0 py-4 justify-content-center">
                  <div class="symbol symbol-50 symbol-light mr-1">
                    <span class="">
                      <router-link
                        v-if="item.token_progress < 100"
                        :to="{
                          name: 'content-run',
                          params: { token: item.token_token },
                        }"
                        class="btn btn-outline-light p-1"
                      >
                        <img :src="$laUtils.contentImagePath(item.content_photo_url)" class="align-self-center info" alt="" style="height: 60px" />
                      </router-link>
                    </span>
                  </div>
                </td>

                <td class="pl-0">
                  <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"> {{ item.texts.name }} </a>
                  <div v-if="false">
                    <span class="font-weight-bolder">Conteúdo:</span>
                  </div>
                </td>
                <td class="text-left pr-0">
                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                    {{ item.attendee_name }} <br />
                    {{ item.attendee_email }}
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                    {{ item.token_token }}
                  </span>
                  <span class="text-muted font-weight-bold">
                    {{ item.purchase_user_white_label_name }}
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                    <div class="progress" v-if="item.token_progress > 0">
                      <div
                        class="progress-bar success"
                        role="progressbar"
                        :style="{
                          width: item.token_progress + '%',
                        }"
                        :aria-valuenow="item.token_progress"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {{ item.token_progress }}%
                      </div>
                    </div>
                    <span v-if="item.token_progress > 0" class="text-left w-100 text-muted"> {{ item.token_progress }}% </span>
                    <span v-if="item.token_progress === 0 || item.token_progress == null" class="text-left w-100 text-muted">
                      {{ $t("GENERAL.NOT_STARTED") | uppercase }}
                      <span v-if="item.purchase_user_id !== item.attendee_id">
                        <br />
                        <button class="btn btn-outline-danger btn-sm mb-2" @click="showModal(item)">
                          <i class="fas fa-minus-square"></i> {{ $t("GENERAL.GIFT_REVOKE") }}
                        </button>
                      </span>
                    </span>
                  </span>
                </td>
                <td class="text-right">
                  <span class="label label-lg label-inline" :class="`label-light-${item.text6}`">
                    {{ $tc("GENERAL." + item.content_type.toUpperCase(), 1) }}
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
    <div class="card-footer">
      <la-paginator v-if="dataToShow.length > 0" @page-click="(bvEvent, page) => getData(page)" :pagination="currentGiftsSentPaginator"> </la-paginator>
    </div>
    <b-modal
      ref="my-modal"
      class="modal-lg"
      :ok-title="$t('GENERAL.GIFT_REVOKE')"
      :ok-variant="'danger'"
      :cancel-title="$t('GENERAL.CANCEL')"
      :title="$t('GENERAL.GIFT_REVOKE')"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @ok="hideModal"
    >
      <div class="d-block" v-if="selectedToken !== null">
        <p class="text-danger">{{ $t("GENERAL.GIFT_REVOKE_WARNING") }}</p>

        <img :src="$laUtils.contentImagePath(selectedToken.content_photo_url)" class="align-self-center info" alt="" style="height: 60px" />
        <p>
          <strong> {{ $t("GENERAL.NAME") }}:</strong> {{ selectedToken.texts.name }}
        </p>
        <p>
          <strong>{{ $t("GENERAL.TOKEN") }}:</strong> {{ selectedToken.token_token }}
        </p>
        <p v-html="selectedToken.texts.description"></p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LaPaginator from "@/view/pages/components/LaPaginator/LaPaginator";
import ContentService from "@/core/services/content.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_GIFTS_SENT } from "@/core/services/store/gifts-sent.module";
import uppercase from "@/core/mixins/uppercase.filter";
import { REVOKE_ATTENDEE_FROM_CUSTOMER } from "@/core/services/store/admin-tokens.module";
import TheToaster from "@/core/services/the-toaster";
import { GET_ATTENDEE_TOKENS } from "@/core/services/store/token.module";

export default {
  name: "my-gifts",
  mixins: [uppercase],
  data() {
    return {
      status: "loading",
      type: "all",
      selectedToken: null,
    };
  },
  components: { LaPaginator },

  computed: {
    ...mapGetters({ layoutConfig: "layoutConfig", giftsSent: "giftsSent/giftsSent", currentGiftsSentPaginator: "giftsSent/currentGiftsSentPaginator" }),

    dataToShow() {
      return this.giftsSent || [];
    },
  },
  methods: {
    showModal(token) {
      this.selectedToken = token;
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$store
        .dispatch("adminTokens/" + REVOKE_ATTENDEE_FROM_CUSTOMER, {
          token: this.selectedToken.token_token,
        })
        .then((response) => {
          this.selectedToken = null;
          this.$refs["my-modal"].hide();
          // TheToaster.success(this.$t("GENERAL.INVITE_SENT"));
          TheToaster.success(response.data.message, false);
          this.changeProgress();
        });
    },
    getContentPhoto(photo_url) {
      return ContentService.getContentPhoto(photo_url);
    },
    changeProgress() {
      let self = this;
      this.status = "loading";
      this.$store
        .dispatch("giftsSent/" + GET_GIFTS_SENT, { page: self.page })
        .then(() => {})
        .finally(() => {
          this.status = "loaded";
        });
    },
    getData(page) {
      this.status = "loading";
      this.$store
        .dispatch("giftsSent/" + GET_GIFTS_SENT, { page })
        .then(() => {})
        .finally(() => {
          this.status = "loaded";
        });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$tc("GENERAL.DASHBOARD", 3), route: "dashboard" }, { title: this.$tc("GENERAL.GIFTS_SENT", 3) }]);
    setTimeout(() => this.getData(1), 600);
    return this.dataToShow;
  },
};
</script>
